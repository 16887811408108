<template>
    <div class="Console">
        <el-row :gutter="8" style="padding-top: 8px">
            <el-col :span="12">
                <el-card shadow="never">
                    <div slot="header" class="clearfix">
                        <span>快捷方式</span>
                    </div>
                    <div>
                        <el-row :gutter="16">
                            <el-col :span="6">
                                <div>
                                    <div
                                        class="ln ln-1"
                                        @click="jump('menu.goods.goods', '商品管理_可售商品管理')"
                                        v-if="hasPrivilege('menu.goods.goods.open')"
                                    >
                                        <div style="display: flex; align-items: center">
                                            <img src="@/assets/console/goods.png" />
                                            <div style="margin-left: 10px">商品管理</div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div>
                                    <div
                                        class="ln ln-2"
                                        style="border-color: #d5d0fe"
                                        @click="jump('menu.stock.loss', '库存管理_商品报损')"
                                        v-if="hasPrivilege('menu.stock.loss.open')"
                                    >
                                        <div style="display: flex; align-items: center">
                                            <img src="@/assets/console/loss.png" />
                                            <div style="margin-left: 10px">报损</div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div>
                                    <div
                                        class="ln ln-3"
                                        style="border-color: #ffe3c6"
                                        v-if="hasPrivilege('menu.stock.surplus.open')"
                                        @click="jump('menu.stock.surplus', '库存管理_商品报溢')"
                                    >
                                        <div style="display: flex; align-items: center">
                                            <img src="@/assets/console/surplus.png" />
                                            <div style="margin-left: 10px">报溢</div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div>
                                    <div
                                        class="ln ln-4"
                                        style="border-color: #d4e3ff"
                                        v-if="hasPrivilege('menu.finance.dailySettle.open')"
                                        @click="jump('menu.finance.dailySettle', '财务管理_收银日结')"
                                    >
                                        <div style="display: flex; align-items: center">
                                            <img src="@/assets/console/finance.png" />
                                            <div style="margin-left: 10px">收银日结</div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <el-card style="margin-top: 8px; font-size: 14px; color: #666" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>待办事项</span>
                    </div>
                    <div>
                        <el-row :gutter="8">
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.purchase.order.open')"
                                    @click="jump('menu.purchase.order', '采购管理_采购订单')"
                                >
                                    <span>采购订单</span>
                                    <span class="purchase">{{ count.purchase.apply || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.purchase.in.open')"
                                    @click="jump('menu.purchase.in', '采购管理_采购入库')"
                                >
                                    <span>采购入库</span>
                                    <span class="purchase">{{ count.purchase.inStock || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.purchase.refund.open')"
                                    @click="jump('menu.purchase.refund', '采购管理_退货申请')"
                                >
                                    <span>采购退货申请</span>
                                    <span class="purchase">{{ count.purchase.refundApply || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.purchase.refundOut.open')"
                                    @click="jump('menu.purchase.refundOut', '采购管理_退货出库')"
                                >
                                    <span>采购退货出库</span>
                                    <span class="purchase">{{ count.purchase.refundOut || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.purchase.selfIn.open')"
                                    @click="jump('menu.purchase.selfIn', '采购管理_单方采购入库')"
                                >
                                    <span>单方采购入库</span>
                                    <span class="purchase">{{ count.purchase.selfIn || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.purchase.selfOut.open')"
                                    @click="jump('menu.purchase.selfOut', '采购管理_单方采购退货')"
                                >
                                    <span>单方采购退货</span>
                                    <span class="purchase">{{ count.purchase.selfOut || 0 }}</span>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="8" style="margin-top: 8px">
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.stock.loss.open')"
                                    @click="jump('menu.stock.loss', '库存管理_报损调整')"
                                >
                                    <span>报损</span>
                                    <span class="stock">{{ count.stock.loss || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.stock.surplus.open')"
                                    @click="jump('menu.stock.surplus', '库存管理_报溢调整')"
                                >
                                    <span>报溢</span>
                                    <span class="stock">{{ count.stock.overflow || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.stock.check.open')"
                                    @click="jump('menu.stock.check', '库存管理_盘点调整')"
                                >
                                    <span>盘点</span>
                                    <span class="stock">{{ count.stock.check || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.stock.receive.open')"
                                    @click="jump('menu.stock.receive', '库存管理_盘点调整')"
                                >
                                    <span>内购</span>
                                    <span class="stock">{{ count.stock.receive || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.stock.allocate.open')"
                                    @click="jump('menu.stock.allocate', '库存管理_调拨申请')"
                                >
                                    <span>调拨申请</span>
                                    <span class="stock">{{ count.stock.allocateApply || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.stock.allocateIn.open')"
                                    @click="jump('menu.stock.allocateIn', '库存管理_调拨入库')"
                                >
                                    <span>调拨入库</span>
                                    <span class="stock">{{ count.stock.allocateIn || 0 }}</span>
                                </div>
                            </el-col>
                            <!-- <el-col :span="4">
                                <div class="todo" @click="jump('menu.stock.allocateOut','库存管理_调拨入库')">
                                    <span>调拨出库</span>
                                    <span class="stock">{{count.stock.allocateOut||0}}</span>
                                </div>
                            </el-col> -->
                        </el-row>
                        <el-row :gutter="8" style="margin-top: 8px">
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.stock.allocateOut.open')"
                                    @click="jump('menu.stock.allocateOut', '库存管理_调拨出库')"
                                >
                                    <span>调拨出库</span>
                                    <span class="stock">{{ count.stock.allocateOut || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.sale.order.open')"
                                    @click="jump('menu.sale.order', '销售管理_批发订单')"
                                >
                                    <span>批发订单</span>
                                    <span class="sale">{{ count.sale.wholeSaleApply || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.sale.out.open')"
                                    @click="jump('menu.sale.out', '销售管理_批发出库')"
                                >
                                    <span>批发出库</span>
                                    <span class="sale">{{ count.sale.wholeSaleOut || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.sale.refund.open')"
                                    @click="jump('menu.sale.refund', '销售管理_批发退货申请')"
                                >
                                    <span>批发退货申请</span>
                                    <span class="sale">{{ count.sale.wholeSaleRefundApply || 0 }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="todo"
                                    v-if="hasPrivilege('menu.sale.refundIn.open')"
                                    @click="jump('menu.sale.refundIn', '销售管理_批发退货入库')"
                                >
                                    <span>批发退货入库</span>
                                    <span class="sale">{{ count.sale.wholeSaleRefundIn || 0 }}</span>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <div>
                    <el-card shadow="never">
                        <div slot="header" class="clearfix">
                            <span>使用文档</span>
                        </div>
                        <div>
                            <a style="text-decoration: none" class="el-button el-button--success" @click="downLoadZip"
                                >说明文档</a
                            >
                        </div>
                    </el-card>
                    <el-card shadow="never" style="margin-top: 8px">
                        <div slot="header" class="clearfix">
                            <span>操作流程</span>
                        </div>
                        <div>
                            <el-row :gutter="8">
                                <el-col :span="8">
                                    <div>
                                        <el-steps direction="vertical" :active="2">
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="
                                                            jump('menu.stock.loss.create', '库存管理_报损调整_新建报损')
                                                        "
                                                    >
                                                        新建报损单
                                                    </el-button>
                                                </div>
                                            </el-step>
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="jump('menu.stock.loss', '库存管理_商品报损')"
                                                        >审核报损单
                                                    </el-button>
                                                </div>
                                            </el-step>
                                        </el-steps>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div>
                                        <el-steps direction="vertical" :active="2">
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="
                                                            jump(
                                                                'menu.stock.surplus.create',
                                                                '库存管理_报溢调整_新建报溢'
                                                            )
                                                        "
                                                    >
                                                        新建报溢单
                                                    </el-button>
                                                </div>
                                            </el-step>
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="jump('menu.stock.surplus', '库存管理_报溢调整')"
                                                        >审核报溢单
                                                    </el-button>
                                                </div>
                                            </el-step>
                                        </el-steps>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div>
                                        <el-steps direction="vertical" :active="4">
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="
                                                            jump(
                                                                'menu.stock.allocate.create',
                                                                '库存管理_调拨申请_新建调拨'
                                                            )
                                                        "
                                                    >
                                                        新建调拨单
                                                    </el-button>
                                                </div>
                                            </el-step>
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="jump('menu.stock.allocate', '库存管理_调拨申请')"
                                                        >审核调拨单
                                                    </el-button>
                                                </div>
                                            </el-step>
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="jump('menu.stock.allocateOut', '库存管理_调拨出库')"
                                                        >调拨出库
                                                    </el-button>
                                                </div>
                                            </el-step>
                                            <el-step>
                                                <div slot="title">
                                                    <el-button
                                                        type="success"
                                                        @click="jump('menu.stock.allocateIn', '库存管理_调拨入库')"
                                                        >调拨入库
                                                    </el-button>
                                                </div>
                                            </el-step>
                                        </el-steps>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                    <el-card
                        v-if="hasPrivilege('biz.system.deptPrivilege.renewal')"
                        shadow="never"
                        style="margin-top: 8px"
                        body-style="height: 163px;overflow-y: scroll;"
                    >
                        <div slot="header" class="clearfix">
                            <span>服务期限提醒</span>
                            <el-button
                                type="success"
                                style="float: right"
                                size="mini"
                                @click="jump('menu.system.dept', '系统管理_部门管理')"
                                v-if="
                                    hasPrivilege('menu.system.dept.show') &&
                                    hasPrivilege('biz.system.deptPrivilege.renewal')
                                "
                                >续费</el-button
                            >
                        </div>
                        <div style="padding: 10px">
                            <div v-for="(item, code) of remind" :key="code">
                                <div style="font-size: 15px; line-height: 1.6">
                                    {{ item.name }}：<span :class="item.isOverTime < 0 ? 'redText' : ''">{{
                                        item.effectiveTime
                                    }}</span
                                    ><span class="redText">{{ item.isOverTime > 0 ? '' : '(已到期)' }}</span>
                                </div>
                            </div>
                            <!-- <div style="font-size: 15px;line-height: 1.5;">鲁谷垦荒人门店：已到期</div>
							<div style="font-size: 15px;line-height: 1.5;">鲁谷垦荒人门店：已到期</div>
							<div style="font-size: 15px;line-height: 1.5;">鲁谷垦荒人门店：已到期</div> -->
                        </div>
                    </el-card>
                    <!--                    <el-card shadow="never" style="margin-top: 8px">-->
                    <!--                        <div>-->
                    <!--                            <a style="text-decoration: none" class="el-button el-button&#45;&#45;success" :href="appUrl">-->
                    <!--                                下载八号地综合零售接单app-->
                    <!--                            </a>-->
                    <!--                        </div>-->
                    <!--                        <div>-->
                    <!--                            <el-image-->
                    <!--                                style="width: 100px; height: 100px"-->
                    <!--                                :src="`${apiHostUrlSuffix}/orderReceiveApp/downloadPageUrlQRCode`"-->
                    <!--                                fit="fill"-->
                    <!--                            />-->
                    <!--                        </div>-->
                    <!--                    </el-card>-->
                    <!--                    <el-card shadow="never" style="margin-top: 8px">-->
                    <!--                        <div>-->
                    <!--                            <a-->
                    <!--                                style="text-decoration: none"-->
                    <!--                                class="el-button el-button&#45;&#45;success"-->
                    <!--                                :href="systemcOrderReceiveAppUrl"-->
                    <!--                            >-->
                    <!--                                下载朗勤心选配送app-->
                    <!--                            </a>-->
                    <!--                        </div>-->
                    <!--                        <div>-->
                    <!--                            <el-image-->
                    <!--                                style="width: 100px; height: 100px"-->
                    <!--                                :src="`${apiHostUrlSuffix}/systemcOrderReceiveApp/downloadPageUrlQRCode`"-->
                    <!--                                fit="fill"-->
                    <!--                            />-->
                    <!--                        </div>-->
                    <!--                    </el-card>-->
                </div>
            </el-col>
        </el-row>
        <sure-dept-license-name-guide />
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import SureDeptLicenseNameGuide from 'views/menu/SureDeptLicenseNameGuide';
export default {
    name: 'Console',
    components: { SureDeptLicenseNameGuide },
    data: function () {
        return {
            apiHostUrlSuffix: this.$proxyApiHostUrlSuffix,
            count: {
                purchase: {
                    apply: 0,
                    inStock: 0,
                    refundApply: 0,
                    refundOut: 0,
                    selfIn: 0,
                    selfOut: 0,
                },
                stock: {
                    loss: 0,
                    overflow: 0,
                    check: 0,
                    receive: 0,
                    allocateApply: 0,
                    allocateIn: 0,
                    allocateOut: 0,
                },
                sale: {
                    wholeSaleApply: 0,
                    wholeSaleOut: 0,
                    wholeSaleRefundApply: 0,
                    wholeSaleRefundIn: 0,
                },
            },
            remind: null,
            appUrl: '',
            systemcOrderReceiveAppUrl: '',
        };
    },
    mounted() {
        new Promise((resolve, reject) => {
            Util.setIntervalAndTimeout(
                () => {
                    return (this.$store.state.metaAllPrivileges || []).length > 0;
                },
                () => {},
                () => {
                    this.handleSelectDept();
                },
                3000
            );
        });
        UrlUtils.QueryRemote(this, '/orderReceiveApp/downloadUrl', (data) => {
            this.appUrl = data;
        });
        UrlUtils.QueryRemote(this, '/systemcOrderReceiveApp/downloadUrl', (data) => {
            this.systemcOrderReceiveAppUrl = data;
        });
    },
    methods: {
        downLoadZip() {
            this.$http({
                url: '/system/backConfig/helpCenter/descDocInfo',
                method: 'get',
            }).then((data) => {
                window.location.href = data.data.data.url;
            });
        },
        // promotion() {
        //     this.$router.push({ name: 'createPromotionType' });
        // },
        jump(flag, path) {
            Util.nameJump(this, flag, path.split('_'));
        },
        handleSelectDept() {
            this.$http
                .get('/indexCountInfo', {
                    params: {},
                })
                .then((rst) => {
                    this.count.purchase.apply = rst.data.data?.purchase?.apply; //采购订单
                    this.count.purchase.inStock = rst.data.data?.purchase?.inStock; //采购入库
                    this.count.purchase.refundApply = rst.data.data?.purchase?.refundApply; //采购退货申请
                    this.count.purchase.refundOut = rst.data.data?.purchase?.refundOut; //采购退货出库
                    this.count.purchase.selfIn = rst.data.data?.purchase?.selfIn; //单方采购入库
                    this.count.purchase.selfOut = rst.data.data?.purchase?.selfOut; //单方采购退货

                    this.count.stock.loss = rst?.data?.data?.stock?.loss; //报损
                    this.count.stock.overflow = rst?.data?.data?.stock?.overflow; //报溢
                    this.count.stock.check = rst?.data?.data?.stock?.check; //盘点
                    this.count.stock.receive = rst?.data?.data?.stock?.receive; //内购
                    this.count.stock.allocateApply = rst?.data?.data?.stock?.allocateApply; //调拨申请
                    this.count.stock.allocateIn = rst?.data?.data?.stock?.allocateIn; //调拨入库
                    this.count.stock.allocateOut = rst?.data?.data?.stock?.allocateOut; //调拨出库

                    this.count.sale.wholeSaleApply = rst.data.data?.sale?.wholeSaleApply; //批发订单
                    this.count.sale.wholeSaleOut = rst.data.data?.sale?.wholeSaleOut; //批发出库
                    this.count.sale.wholeSaleRefundApply = rst.data.data?.sale?.wholeSaleRefundApply; //批发退货申请
                    this.count.sale.wholeSaleRefundIn = rst.data.data?.sale?.wholeSaleRefundIn; //批发退货入库
                });
            if (this.hasPrivilege('biz.system.deptPrivilege.renewal')) {
                this.$http
                    .get('/system/renewCode/deptInfo', {
                        params: {},
                    })
                    .then((rst) => {
                        this.remind = rst.data.data;
                    });
            }
        },
    },
};
</script>

<style scoped>
.Console {
    background-color: lightyellow;
    left: 0;
    right: 0;
    top: 0;
}

.Console .ln {
    height: 102px;
    border: 1px solid #a2ffdd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Console .ln-1:hover {
    background-color: #dafff1;
}
.Console .ln-2:hover {
    background-color: #eceaff;
}
.Console .ln-3:hover {
    background-color: #fff5eb;
}
.Console .ln-4:hover {
    background-color: #eaf1ff;
}

.Console .todo {
    background-color: #f2f2f2;
    height: 90px;
    border-radius: 8px;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.Console .todo .purchase {
    font-size: 30px;
    font-weight: bolder;
    color: orange;
}

.Console .todo .stock {
    font-size: 30px;
    font-weight: bolder;
    color: dodgerblue;
}

.Console .todo .sale {
    font-size: 30px;
    font-weight: bolder;
    color: #19c989;
}
.redText {
    color: red;
}
</style>
