<template>
    <div>
        <el-dialog
            :visible.sync="sureDeptLicenseNameGuide"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :destroy-on-close="true"
            width="600px"
            height="480px"
            title="确认信息"
        >
            <p>为保证系统内信息正确性，请重新填写营业执照名称</p>
            <el-form
                :model="sureDeptLicenseNameForm"
                :rules="sureDeptLicenseNameForm.rules"
                ref="sureDeptLicenseNameFormRef"
            >
                <el-table :data="sureDeptLicenseNameForm.sureDeptLicenseNameDatas" height="400px">
                    <el-table-column property="name" label="机构名称" width="220"></el-table-column>
                    <el-table-column property="deptLicenseName" label="营业执照名称" width="330">
                        <template slot-scope="scope">
                            <el-form-item
                                style="height: 45px"
                                :prop="'sureDeptLicenseNameDatas.' + scope.$index + '.deptLicenseName'"
                                :rules="sureDeptLicenseNameForm.rules.deptLicenseName"
                            >
                                <el-input v-model="scope.row.deptLicenseName" maxlength="50" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sureSaveDeptLicenseName">确认提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const cardBodyStyleConst = 'display: flex;justify-content: center;align-items: center;';

export default {
    name: 'SureDeptLicenseNameGuide',
    data() {
        const checkChina = (rule, value, callback) => {
            var regChina = /^[\u4e00-\u9fa5\s（\）]+$/;
            if (!regChina.test(value)) {
                callback(new Error('请填写正确营业执照名称'));
            } else if (this.isNull(value)) {
                callback(new Error('最少填写两个汉字'));
            } else if (value.includes(' ')) {
                callback(new Error('请填写正确营业执照名称'));
            } else if (value.trim().length < 2) {
                callback(new Error('最少填写两个汉字'));
            } else {
                callback();
            }
        };
        return {
            sureDeptLicenseNameGuide: false,
            sureDeptLicenseNameForm: {
                sureDeptLicenseNameDatas: [],
                rules: {
                    deptLicenseName: [
                        { required: true, message: '营业执照名称不可为空', trigger: ['blur', 'change'] },
                        { validator: checkChina, trigger: 'blur' },
                    ],
                },
            },
            cardBodyStyle: cardBodyStyleConst,
            itemsCardBodyStyle: cardBodyStyleConst + 'flex-direction: column;',
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        isNull(str) {
            if (str === '') return true;
            var regu = '^[ ]+$';
            var re = new RegExp(regu);
            return re.test(str);
        },
        getList() {
            this.$http.get('/system/dept/noSureDeptLicenseName', {}).then((res) => {
                if (res.data && res.data.data && res.data.data.length > 0) {
                    this.sureDeptLicenseNameForm.sureDeptLicenseNameDatas = res.data.data;
                    this.sureDeptLicenseNameGuide = true;
                } else {
                    this.close();
                }
            });
        },
        sureSaveDeptLicenseName() {
            this.$refs.sureDeptLicenseNameFormRef.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                const data = [];
                try {
                    console.warn('checkAge');
                    await this.sureDeptLicenseNameForm.sureDeptLicenseNameDatas.forEach((p) => {
                        if (!p.deptLicenseName.trim()) {
                            this.$message.error(p.name + '门店营业执照名称不可为空！');
                            throw new Error();
                        } else {
                            data.push({ code: p.code, deptLicenseName: p.deptLicenseName });
                        }
                    });
                } catch (e) {
                    return false;
                }
                await this.$http
                    .post('/system/dept/sureDeptLicenseName', data, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((res) => {
                        this.close();
                    });
            });
        },
        close() {
            this.sureDeptLicenseNameGuide = false;
            this.toggleTabRouteTo('guide');
            this.$store.commit('delTabRoute', 'sureDeptLicenseNameGuide');
        },
        async toggleTabRouteTo(tabRouteName) {
            const tabRoute = this.$store.state.tabRoutes.find((e) => e.routeName === tabRouteName);
            //切换到tab时，使用缓存
            if (tabRoute) {
                await this.$router.push({
                    name: tabRoute.routeName,
                    query: tabRoute.query,
                    params: { ...tabRoute.params },
                });
            } else {
                //不存在，则只有使用name路由，无法使用没有保存的query和params
                await this.$router.push({ name: tabRouteName });
            }
        },
    },
};
</script>
